/* istanbul ignore file */
import { PageLoading, SettingsType, withErrorBoundary, ErrorPage, InvalidAdviesboxResultErrorPage } from "adviesbox-shared";
import React, { ReactElement, useCallback } from "react";
import { DossierRouteParams } from "../shared/types";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import Documenten from "./documenten";
import { mapDocumentenDlTargetToUiField } from "./infra/map-documenten-dl-target-to-ui-field";
import { mapDocumentenDlNaarUi } from "./infra/map-documenten-dl-to-ui";

const DocumentenAjax = (): ReactElement => {
  const createUrl = useCallback((s: SettingsType, p: DossierRouteParams): string => {
      return `${s.hdnDossierOrigin}/dossier/${p.dossierId}/documenten`;
    },
    []
  );

  const { loading, error, data, platformData } = useAdviesboxData(
    createUrl,
    p => p.dossierId,
    mapDocumentenDlNaarUi,
    () => {},
    mapDocumentenDlTargetToUiField,
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <Documenten saveData={async () => null} {...data} />;
};

DocumentenAjax.displayName = "DocumentenAjax";
export default withErrorBoundary(DocumentenAjax);
