import { LocalDate } from "@js-joda/core";
import {
  KlantnaamReadOnly,
  ZoekresultaatAdviseur,
  DossierSamenvattingOutput,
  DossierSamenvatting,
  SoftwareKoppelingOptions
} from "../../.generated/hdndossier/hdndossiertypes";

import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { laatsteDossierSchema, zoekschermSchema, ZoekschermState } from "./zoekscherm-schema";

export enum AdviesdossierStatusOptions {
  Geen = "Geen",
  InBehandeling = "InBehandeling",
  Actief = "Actief",
  NietActief = "NietActief"
}

function getNaamAanvrager(aanvrager: KlantnaamReadOnly): string {
  return `${(aanvrager.voornamen || "") + "\xa0"}${
    aanvrager.voorvoegsel ? aanvrager.voorvoegsel + "\xa0" : ""
  }${aanvrager.achternaam || /* istanbul ignore next */ ""}`.trim();
}

function getStatus(afhandeldDoorServiceprovider: boolean, softwareKoppeling: SoftwareKoppelingOptions | null, status: string): string {
  if ((afhandeldDoorServiceprovider || softwareKoppeling) && status === "Verzonden") return "Aanvraag extern afgehandeld";
  return status;
}

export function mapKlantNamen(aanvrager1: KlantnaamReadOnly, aanvrager2?: KlantnaamReadOnly | null): string {
  const naamAanvrager = getNaamAanvrager(aanvrager1);
  const naamPartner =
    aanvrager2 && (aanvrager2.achternaam || aanvrager2.voornamen)
      ? `,\xa0${getNaamAanvrager(aanvrager2)}`
      : aanvrager2?.geboortedatum
      ? /* istanbul ignore next */ ",\xa0Aanvrager\xa02"
      : "";
  return naamAanvrager + naamPartner;
}

export function mapGeboorteData(aanvrager1: KlantnaamReadOnly, aanvrager2?: KlantnaamReadOnly | null): LocalDate[] {
  const geboortDataArray: LocalDate[] = [];
  if (aanvrager1?.geboortedatum) geboortDataArray.push(LocalDate.parse(aanvrager1?.geboortedatum.substring(0, 10)));
  if (aanvrager2?.geboortedatum) geboortDataArray.push(LocalDate.parse(aanvrager2?.geboortedatum.substring(0, 10)));
  return geboortDataArray;
}

export const mapStatus2ReadableString = {
  Geen: "Geen",
  InBehandeling: "In behandeling",
  Actief: "Actief",
  NietActief: "Niet actief"
};

export const mapAanleidingen2ReadableString = {
  AankoopNieuwbouw: "Aankoop van een nieuwbouw woning",
  AankoopBestaandeBouw: "Aankoop van een bestaande woning",
  HuidigeHypotheekWijzigen: "Huidige hypotheek wijzigen",
  GeenFinanciering: "Geen financiering"
};

export const dl2ui = createMapToUi(zoekschermSchema).from<DossierSamenvatting[]>({
  zoeken: _ => null,
  nieuweKlant: _ => null,
  laatsteDossiers: v => ({
    inView: 10,
    aantalDossiers: v.length,
    laatsteDossiers: v.map(
      (val): ReturnType<typeof laatsteDossierSchema.cast> => {
        const {
          aanvraagVersie,
          adviesOmschrijving,
          adviesdossierId,
          afhandeldDoorServiceprovider,
          softwareKoppeling,
          hdnDossierId,
          klantNaam,
          laatstOntvangenBericht,
          soortAanvraag,
          startDatum,
          status
        } = val;

        return {
          aanvraagVersie: aanvraagVersie || 0,
          afhandeldDoorServiceprovider: afhandeldDoorServiceprovider,
          softwareKoppeling: softwareKoppeling,
          adviesOmschrijving: adviesOmschrijving || /*istanbul ignore next */ "",
          adviesdossierId: adviesdossierId || /*istanbul ignore next  */ "",
          hdndossierId: hdnDossierId || /*istanbul ignore next  */ "",
          klantNaam: klantNaam || /*istanbul ignore next  */ "",
          laatstOntvangenBericht: laatstOntvangenBericht || null,
          soortAanvraag: soortAanvraag || /*istanbul ignore next */ "",
          startDatum: startDatum || /*istanbul ignore next  */ "",
          status: getStatus(afhandeldDoorServiceprovider, softwareKoppeling, status)
        };
      }
    )
  }),
  recentGeopendError: _ => false,
  totalNumberOfPages: _ => 0,
  totalNumberOfResults: _ => 0
});

export function mapZoekschermDlToUi(data?: DossierSamenvattingOutput | string | null): ZoekschermState | null {
  if (!data || typeof data === "string" || !data.hdnDossiers || !data.isValid) {
    return null;
  }

  return dl2ui(data.hdnDossiers);
}

export function getNaamPersoon(client?: KlantnaamReadOnly | ZoekresultaatAdviseur | null): string {
  if (!client) return "";
  return `${client.voornamen || ""}${client.voorvoegsel ? "\xa0" + client.voorvoegsel : ""} ${client.achternaam ||
    /*istanbul ignore next  */ ""}`.trim();
}
