/* istanbul ignore file */
import React, { ReactElement, useState } from "react";
import { CellInfo } from "react-table-6";
import { useRequestInit, Icon, LoadingSpinner } from "adviesbox-shared";
import { Button } from "react-bootstrap";

export const DownloadButton = (cell: CellInfo): ReactElement => {
  const { params, settings, requestInit } = useRequestInit();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onClick = async () => {
    setLoading(true);
    const url = `${settings.hdnDossierOrigin}/dossier/${params.dossierId}/document/${cell.original.hdnDocumentId}`;
    await fetch(url, requestInit)
      .then(async response => {
        if (!response.ok) throw new Error("Download is mislukt");
        const fileName = response.headers.get("content-disposition")?.split('filename=')[1].split(';')[0] || "unknown.pdf";
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => setLoading(false))
      .catch(() => setError(true));
  };

  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <Button
        disabled={loading || error}
        onClick={onClick}
        data-testid={`btn-info-${cell.index}`}
        id={`btn-info-${cell.index}`}
        variant="outline-secondary"
        aria-label="bewerk knop"
      >
        {error ? (
          <Icon name="kruis" alt="Error" />
        ) : loading ? (
          <LoadingSpinner disablePadding />
        ) : (
          <Icon name="download" alt="Download" />
        )}
      </Button>
    </div>
  );
};
