/* istanbul ignore file */
import React, { ReactElement, useState, ChangeEvent } from "react";
import { CellInfo } from "react-table-6";
import { useRequestInit, Icon, LoadingSpinner } from "adviesbox-shared";
import { Button } from "react-bootstrap";
import { HdnDocumentStatus } from "../../../.generated/hdndossier/hdndossiertypes";
import { useFormikContext } from "formik";
import { DocumentenSchemaType } from "../../infra/documenten-schema";

export const UploadButton = (cell: CellInfo): ReactElement => {
  const { setFieldValue } = useFormikContext<DocumentenSchemaType>();
  const { params, user, settings } = useRequestInit();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event && event.target.files) {
      setLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("DocumentId", cell.original.hdnDocumentId);
      formData.append("Attachement", file);

      const url = `${settings.foundationOrigin}/HdnOnline/bus/NieuwDocumentVoorAanvraag`;
      await fetch(url, {
        method: "POST",
        headers: {
          authorization: `${user?.token_type} ${user?.access_token}`,
          "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
          vestigingId: params.vestiging ?? ""
        },
        body: formData
      })
        .then(response => {
          if (response.status >= 400) throw Error("Upload is mislukt");
          setFieldValue(`gevraagdeDocumenten[${cell.index}].status`, HdnDocumentStatus.Aangeboden);
          setFieldValue(`gevraagdeDocumenten[${cell.index}].bestandsNaam`, file.name);
        })
        .finally(() => setLoading(false))
        .catch(() => setError(true));
    }
  };

  const handeClick = () => {
    const input = document.getElementById(`fileUpload-${cell.index}`);
    if (input) input.click();
  };

  const status = cell.original.status;
  if (
    status === HdnDocumentStatus.Aangeboden ||
    status === HdnDocumentStatus.Akkoord ||
    status === HdnDocumentStatus.InBehandeling ||
    status === HdnDocumentStatus.Ontvangen ||
    status === HdnDocumentStatus.Vervallen ||
    status === HdnDocumentStatus.Verzonden ||
    status === HdnDocumentStatus.VoorlopigAkkoord
  ) {
    return <></>;
  }

  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <input id={`fileUpload-${cell.index}`} type="file" onChange={handleChange} hidden />
      <Button
        disabled={loading}
        onClick={handeClick}
        data-testid={`btn-info-${cell.index}`}
        id={`btn-info-${cell.index}`}
        variant="outline-secondary"
        aria-label="bewerk knop"
      >
        {error ? (
          <Icon name="kruis" alt="Error" />
        ) : loading ? (
          <LoadingSpinner disablePadding />
        ) : (
          <Icon name="upload" alt="upload" />
        )}
      </Button>
    </div>
  );
};
