/* istanbul ignore file */
import { UiName, FieldMap } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";
import { DossierOutput } from "../../.generated/hdndossier/hdndossiertypes";

export function mapDossierDlTargetToUiField(target: string): UiName | null {
  const map: FieldMap<DossierOutput> = {};

  return target2field(map, target);
}
