import {
  ComponentDecorator,
  FormikProps,
  withFormik,
  FormikBag,
  FormikErrors,
  validateYupSchema,
  yupToFormErrors,
  getIn
} from "formik";
import Yup from "yup";
import { UiError } from "../types";
import { voegApiFoutToe } from "../helpers";

/* istanbul ignore next */
const noop = (): void => {};

export type WithAdviesBoxFormikConfig<OuterProps, Values> = {
  handleSubmit?: (values: Values, formikBag: FormikBag<OuterProps, Values>) => void;
  mapPropsToValues: (props: OuterProps) => Values;
  validationSchema: Yup.ObjectSchema<any> | ((props: OuterProps) => Yup.ObjectSchema<any>);
  createValidationContext?: (props: OuterProps, values: Values) => any;
  mapPropsToErrors?: ((props: OuterProps) => FormikErrors<Values>) | undefined;
  enableReinitialize?: boolean | undefined;
};

export function withAdviesboxFormik<Props extends object, Values>({
  handleSubmit = noop,
  mapPropsToValues,
  validationSchema,
  createValidationContext,
  mapPropsToErrors,
  enableReinitialize
}: WithAdviesBoxFormikConfig<Props, Values>): ComponentDecorator<Props, Props & FormikProps<Values>> {
  return withFormik<Props, Values>({
    mapPropsToValues,
    handleSubmit,
    enableReinitialize,
    validateOnChange: false,
    validate: async (values, props): Promise<FormikErrors<Values> | object> => {
      const platformApiFouten: UiError[] | null = getIn(values, "platformApiFouten");

      try {
        if (typeof validationSchema === "function") {
          validationSchema = validationSchema(props);
        }

        const context = typeof createValidationContext === "function" ? createValidationContext(props, values) : {};

        await validateYupSchema(values, validationSchema, true, context);
      } catch (yupErrors) {
        const formikErrors = voegApiFoutToe(yupToFormErrors(yupErrors), platformApiFouten);

        return formikErrors;
      }

      return voegApiFoutToe({}, platformApiFouten);
    },
    mapPropsToErrors
  });
}
