import React, { ReactElement } from "react";
import { LabeledTextInput, LabeledSelectInput } from "adviesbox-shared";
import { connect } from "formik";
import { LabelValuePairs } from "adviesbox-shared/utils/types";

const Info = (): ReactElement => {
  const options: LabelValuePairs = [
    {
      label: "-- Selecteer een status --",
      value: ""
    },
    {
      label: "Loopt",
      value: "Loopt"
    },
    {
      label: "Afgerond",
      value: "Afgerond"
    },
    {
      label: "Vervallen",
      value: "Vervallen"
    },
  ]

  return (
    <div className="px-1">
      <LabeledTextInput name="dossierNummer" caption="Dossiernummer" readonly />
      <LabeledTextInput name="product" caption="Product" readonly />
      <LabeledTextInput name="partij" caption="Partij" readonly />
      <LabeledTextInput name="hdnNummerOntvanger" caption="HDN-nummer ontvanger" readonly />
      <LabeledTextInput name="hdnNummerVerzender" caption="HDN-nummer verzender" readonly />
      <LabeledSelectInput name="status" caption="Status" options={options} />
    </div>
  );
};

export default connect(Info);
