import * as Yup from "yup";
import { InferType } from "yup";
import { yupEnum } from "../../shared/utils/yup-enum";
import { HdnDossierStatus, SoftwareKoppelingOptions } from "../../.generated/hdndossier/hdndossiertypes";

export const logboekItemSchema = Yup.object().shape({
  berichtId: Yup.string().default(""),
  datum: Yup.string().default(""),
  soortBericht: Yup.string()
    .nullable()
    .default(null),
  status: Yup.string(),
  statusMelding: Yup.string()
    .nullable()
    .default(null),
});

export const dossierSchema = Yup.object().shape({
  dossierNummer: Yup.string()
    .nullable()
    .default(null),
  product: Yup.string()
    .nullable()
    .default(null),
  partij: Yup.string()
    .nullable()
    .default(null),
  hdnNummerVerzender: Yup.string()
    .nullable()
    .default(null),
  hdnNummerOntvanger: Yup.string()
    .nullable()
    .default(null),
  afhandeldDoorServiceprovider: Yup.boolean(),
  softwareKoppeling: Yup.mixed<SoftwareKoppelingOptions>()
    .oneOf([...Object.values(SoftwareKoppelingOptions), null])
    .nullable()
    .default(null),
  status: yupEnum(HdnDossierStatus),
  logboekregels: Yup.array(logboekItemSchema).default([])
});

export type DossierSchemaType = InferType<typeof dossierSchema>;
export type DossierProps = InferType<typeof dossierSchema>;
