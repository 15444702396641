import { ReactElement } from "react";
import { createSpanWithId, convertAndFormatUtcToTimeZone } from "adviesbox-shared";
import { Column } from "react-table-6";
import { getStatus } from "../infra/map-sx-status";

export const laatsteDossierColumns: Column[] = [
  {
    Header: "Startdatum",
    accessor: "startDatum",
    id: "startDatum",
    Cell: (c): ReactElement => {
      var startDatum = "";

      /* istanbul ignore else */
      if (c.original.startDatum) {
        startDatum = convertAndFormatUtcToTimeZone(c.original.startDatum) || "";
      }

      return createSpanWithId(c.index, 0, startDatum, startDatum, "laatst-geopend");
    }
  },
  {
    Header: "Dossiernummer",
    accessor: "hdndossierId",
    id: "hdndossierId",
    minWidth: 180,
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 1, c.original.hdndossierId, c.original.hdndossierId, "dossiers-id")
  },
  {
    Header: "Klant",
    accessor: "klantNaam",
    id: "klantNaam",
    Cell: (c): ReactElement => {
      return createSpanWithId(c.index, 2, c.original.klantNaam, c.original.klantNaam, "klantNaam");
    }
  },
  {
    Header: "Soort aanvraag",
    accessor: "soortAanvraag",
    id: "soortAanvraag",
    Cell: (c): ReactElement =>
      createSpanWithId(c.index, 3, c.original.soortAanvraag, c.original.soortAanvraag, "soortAanvraag")
  },
  {
    Header: "Laatste bericht",
    accessor: "status",
    id: "status",
    Cell: (c): ReactElement =>
      createSpanWithId(
        c.index,
        4,
        c.original.laatstOntvangenBericht 
          ? /*istanbul ignore next */ getStatus(c.original.laatstOntvangenBericht, c.original.afhandeldDoorServiceprovider, c.original.softwareKoppeling) 
          : "",
        c.original.laatstOntvangenBericht 
          ? /*istanbul ignore next */ getStatus(c.original.laatstOntvangenBericht, c.original.afhandeldDoorServiceprovider, c.original.softwareKoppeling) 
          : "",
        "laatste-dossiers"
      )
  },
  {
    Header: "Datum ontvangen",
    accessor: "laatstOntvangenBericht",
    id: "openedOn",
    Cell: (c): ReactElement => {
      var laatstOntvangenBerichtDt = "";
      if (c.original.laatstOntvangenBericht) {
        laatstOntvangenBerichtDt = convertAndFormatUtcToTimeZone(c.original.laatstOntvangenBericht.datum) || "";
      }

      return createSpanWithId(c.index, 5, laatstOntvangenBerichtDt, laatstOntvangenBerichtDt, "laatst-geopend");
    }
  }
];
