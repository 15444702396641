import { MenuConfigType } from "adviesbox-shared";
import { DossierRouteParams } from "../shared/types";

export const menuConfig = (params: DossierRouteParams): MenuConfigType[] => [
    {
      name: "advies",
      lists: [
        {
          name: "dossier",
          title: "HDN dossier",
          icon: "menuadviesinstellingen",
          link: `/vestiging/${params.vestiging}/dossier/${params.dossierId}/info`
        },
        {
          name: "documenten",
          title: "Documenten",
          icon: "copy",
          link: `/vestiging/${params.vestiging}/dossier/${params.dossierId}/documenten`
        },
    ]
  }
];
