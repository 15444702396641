import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  HdnBericht,
  DossierOutput,
  Dossier,
  HdnBerichtStatus
} from "../../.generated/hdndossier/hdndossiertypes";
import {
  DossierSchemaType,
  dossierSchema,
  logboekItemSchema
} from "./dossier-schema";
import { hasValue } from "adviesbox-shared";

export function mapStatus(status: HdnBerichtStatus): string {
  switch (status) {
    case HdnBerichtStatus.TijdelijkeFout:
      return "Tijdelijke fout";
    default:
      return status;
  }
}

export const mapLogboekItem = createMapToUi(logboekItemSchema).from<HdnBericht>(
  {
    berichtId: (v) => v.hdnBerichtId,
    datum: (v) => v.datum,
    soortBericht: (v) => v.berichtType,
    status: (v) => mapStatus(v.status),
    statusMelding: (v) => v.statusMelding
  }
);

const mapLogBoekRegels = (dossier: Dossier) => {
  let logboekRegels =
    dossier.hdnBerichten?.map((entry) => mapLogboekItem(entry)) ?? [];
  logboekRegels = logboekRegels.map((x) => {
    if (
      (dossier.afhandeldDoorServiceprovider && x.status === "Verzonden") ||
      (dossier.softwareKoppeling && x.status === "Aangeboden")
    ) {
      return { ...x, status: "Aanvraag extern afgehandeld" };
    }
    return x;
  });
  return logboekRegels;
};

export const extractProduct = (input: string) => {
  if (!hasValue(input)) return input;
  const regexp = new RegExp("^(\\s*.*?\\s)(.*)");
  const match = input.match(regexp);
  if (match && match?.length > 1) {
    return match[2];
  }
  return input;
};

const mapDossier = createMapToUi(dossierSchema).from<Dossier>({
  dossierNummer: (v) => v.hdnDossierId,
  product: (v) => extractProduct(v.product || ""),
  partij: (v) => v.ontvangerNaam,
  hdnNummerOntvanger: (v) => v.ontvangerNrHdn,
  hdnNummerVerzender: (v) => v.verzenderNrHDN,
  status: (v) => v.status,
  afhandeldDoorServiceprovider: (v) => v.afhandeldDoorServiceprovider,
  softwareKoppeling: (v) => v.softwareKoppeling,
  logboekregels: (v) => mapLogBoekRegels(v)
});

export function mapDossierDlNaarUi(
  dossierId: string,
  data: DossierOutput
): DossierSchemaType | null {
  if (!dossierId) return null;
  return mapDossier(data.dossier);
}
