import React, { ReactElement } from "react";
import { Card, useRequestInit, ErrorPage } from "adviesbox-shared";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { FormikContextType } from "formik";
import { WithSaveData } from "../shared/utils/save-data";
import { DocumentenSchemaType, DocumentenProps, documentenSchema } from "./infra/documenten-schema";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { DossierRouteParams } from "../shared/types";
import Binnengekomen from "./binnengekomen/binnengekomen";
import AanTeLeverenDocumenten from "./aan-te-leveren/aan-te-leveren";
import ReedsAangeleverde from "./reeds-aangeleverde/reeds-aangeleverde";

const Documenten = (
  formikContext: FormikContextType<DocumentenSchemaType> & WithSaveData<DocumentenProps>
): ReactElement => {
  const { user } = useRequestInit<DossierRouteParams>();
  /* istanbul ignore else */ if (!user) return <ErrorPage error={Error("Geen gebruiker of geen rechten toegekend voor deze gebruiker")} data={null} />;

  return (
    <>
      <div className="d-row py-2">
        <div className="col-12 col-lg-8">
          <Card title="Binnengekomen documenten">
            <Binnengekomen />
          </Card>
          <Card title="Aan te leveren documenten">
            <AanTeLeverenDocumenten />
          </Card>
          <Card title="Reeds aangeleverde documenten">
            <ReedsAangeleverde />
          </Card>
        </div>
        <DevDebug />
      </div>
    </>
  );
};

Documenten.displayName = "Documenten";
export default withAdviesboxFormik<DocumentenProps & WithSaveData<DocumentenSchemaType>, DocumentenSchemaType>({
  mapPropsToValues: (e: DocumentenProps): DocumentenSchemaType => e,
  validationSchema: documentenSchema
})(Documenten);
