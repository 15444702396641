import { HdnDocumentStatus } from "../../.generated/hdndossier/hdndossiertypes";

export function mapStatus(status: HdnDocumentStatus): string {
    switch (status) {
        case HdnDocumentStatus.InBehandeling:
            return "In behandeling";
        case HdnDocumentStatus.VoorlopigAkkoord:
            return "Voorlopig akkoord";
        default:
            return status;
    }
}