import { createContext } from "react";

export type RouteParams = {
  vestiging: string;
  dossierId: string;
};

const defaultMatch: RouteParams = {
  vestiging: "",
  dossierId: ""
};

const ParamRouteContext = createContext(defaultMatch);

export default ParamRouteContext;
