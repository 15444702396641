import { Button, Modal as ModalBootstrap } from "react-bootstrap";
import React, { ReactElement, useContext } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit, SettingsContext, ErrorPage, PageLoading } from "adviesbox-shared";
import { BerichtenXmlOutput } from "../../.generated/hdndossier/hdndossiertypes";
import XMLViewer from "react-xml-viewer";

type logboekXmlModalProps = {
  berichtId: string;
  setModalOpen: (val: boolean) => void;
  isModalOpen: boolean;
};
export const LogboekXmlModal = ({ berichtId, isModalOpen, setModalOpen }: logboekXmlModalProps): ReactElement => {
  const settings = useContext(SettingsContext);
  const { requestInit } = useRequestInit();
  const url = `${settings.hdnDossierOrigin}/bericht?berichtId=${berichtId}`;
  const result = useAbortableFetch<BerichtenXmlOutput>(url, requestInit);

  /* istanbul ignore next */
  if (result.error) {
    return <ErrorPage error={result.error} />;
  }

  /* istanbul ignore next */
  if (result.loading || !result.data) {
    return <PageLoading />;
  }

  const xml = typeof result.data === "string" ? "" : result.data.xml ?? "";

  return (
    <>
      <ModalBootstrap show={isModalOpen} backdrop={"static"} size={"xl"}>
        <ModalBootstrap.Header>
          <ModalBootstrap.Title>XML Data</ModalBootstrap.Title>
        </ModalBootstrap.Header>
        <ModalBootstrap.Body>
          <XMLViewer xml={xml} style={{ maxHeight: 500, overflow: "auto" }} />
        </ModalBootstrap.Body>
        <ModalBootstrap.Footer>
          <Button id="btnsluiten" data-testid="btnsluiten" variant="primary" onClick={() => setModalOpen(false)}>
            Sluiten
          </Button>
        </ModalBootstrap.Footer>
      </ModalBootstrap>
    </>
  );
};
