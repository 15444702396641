import React, { ReactElement } from "react";
import { connect, useFormikContext } from "formik";
import {
  DataGrid,
  AdviesBoxColumn,
  createSpanWithId,
  getFormattedDate,
} from "adviesbox-shared";
import { DownloadButton } from "./download-button/download-button";
import { DocumentenSchemaType } from "../infra/documenten-schema";

const BinnengekomenDocumenten = (): ReactElement => {
  const { setFieldValue, values } = useFormikContext<DocumentenSchemaType>();
  const columns: AdviesBoxColumn[] = [
    {
      Header: "Naam",
      accessor: "documentSoort",
      Cell: (c): ReactElement => {
        return createSpanWithId(
          c.index,
          0,
          c.original.documentSoort,
          c.original.documentSoort
        );
      },
      width: 150,
    },
    {
      Header: "Ontvangen op",
      accessor: "datum",
      Cell: (c): ReactElement => {
        const datum = getFormattedDate(c.original.datum);
        return createSpanWithId(c.index, 0, datum, datum);
      },
      width: 150,
    },
    {
      id: "download",
      Cell: DownloadButton,
      width: 40,
    },
  ];

  /* istanbul ignore next */
  const onDataGridChange = (data?: DocumentenSchemaType[]): void => {
    if (!data || !data.length) return;
    setFieldValue("binnengekomenDocumenten", data);
  };

  return (
    <div className="m-2 pb-2">
      <DataGrid
        name={"binnengekomenDocumenten"}
        showButtonAddRow={false}
        columns={columns}
        resizable={false}
        sortable={true}
        sortedCallback={onDataGridChange}
        filterable={false}
      />
      <div className="pl-2">
        {values.binnengekomenDocumenten.length === 0 && (
          <p>Er is nog geen document binnengekomen</p>
        )}
      </div>
    </div>
  );
};

export default connect(BinnengekomenDocumenten);
