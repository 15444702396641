import { AdviesBoxColumn, createSpanWithId, getFormattedDate } from "adviesbox-shared";
import { ReactElement } from "react";
import { mapStatus } from "../shared/utils/map-status";
import { UploadButton } from "./aan-te-leveren/upload-button/upload-button";

export const documentKolommen: AdviesBoxColumn[] = [
  {
    Header: "Naam",
    accessor: "documentSoort",
    Cell: (c): ReactElement => {
      return createSpanWithId(c.index, 0, c.original.documentSoort, c.original.documentSoort);
    },
    width: 175
  },
  {
    Header: "Betreft",
    accessor: "betreft",
    Cell: (c): ReactElement => {
      return createSpanWithId(c.index, 0, c.original.betreft, c.original.betreft);
    },
    width: 125
  },
  {
    Header: "Aanleveren voor",
    accessor: "aanleverenVoorDatum",
    Cell: (c): ReactElement => {
      const datum = c.original.aanleverenVoorDatum
        ? getFormattedDate(c.original.aanleverenVoorDatum)
        : c.original.aanleverenVoorMoment ?? /* istanbul ignore next */ "";
      return createSpanWithId(c.index, 0, datum, datum);
    },
    width: 150
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: (c): ReactElement => {
      const status = mapStatus(c.original.status);
      return createSpanWithId(c.index, 3, status, status);
    },
    width: 150
  },
  {
    Header: "Bestandsnaam",
    accessor: "bestandsNaam",
    Cell: (c): ReactElement => {
      return createSpanWithId(c.index, 0, c.original.bestandsNaam, c.original.bestandsNaam);
    },
    width: 125
  },
  {
    id: "upload",
    Cell: UploadButton,
    width: 30
  }
];
