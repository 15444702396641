import { Icon, FormFirstFocus } from "adviesbox-shared";
import { Form } from "formik";
import React, { ReactElement, useState } from "react";
import { Debug } from "../shared/components/formik/Debug";
import TopNavbar from "../shared/components/topnav-dossier/TopNavbar";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { zoekschermSchema, ZoekschermState } from "./infra/zoekscherm-schema";
import LaatsteDossiers from "./laatste-dossiers/laatste-dossiers";
import Zoeken from "./zoeken/zoeken";
import classes from "./zoekscherm.module.scss";

const Zoekscherm = (): ReactElement => {
  const [showDossiers, setShowDossiers] = useState(true);
  /*istanbul ignore next*/
  const toggleShowDossiers = (): void => {
    setShowDossiers(!showDossiers);
  };

  return (
    <div className={classes.loader_correction}>
      <TopNavbar home={true} />
      <FormFirstFocus>
        <Form>
          <div className={`${classes.zoekscherm_header} py-4`}>
            <div className="mb-2 d-flex">
              <h1 className="mx-auto">Adviesbox HDN Dossier</h1>
            </div>
            <Zoeken />
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className={`card card-adviesbox ${classes.zoeken_cards}`}>
                  <div
                    onClick={toggleShowDossiers}
                    className={`${classes.clickable} d-flex flex-row flex-wrap`}
                    id="toggle-show-dossiers"
                  >
                    <h2 className={"m-0 align-self-center"}>Dossiers met laatst ontvangen berichten</h2>
                    <div className={`${classes.icon} ml-auto`}>
                      <>
                        {/*istanbul ignore next */ !showDossiers && <Icon name="chevron" alt="pijl-omlaag" />}
                        {showDossiers && (
                          <div className={classes.chevron_up}>
                            <Icon name="chevron" alt="pijl-omhoog" />
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                  <LaatsteDossiers showDossiers={showDossiers} />
                </div>
              </div>
            </div>
          </div>

          <Debug />
        </Form>
      </FormFirstFocus>
    </div>
  );
};

Zoekscherm.displayName = "Zoekscherm";

export default withAdviesboxFormik<ZoekschermState, ZoekschermState>({
  mapPropsToValues: (val): ZoekschermState => val,
  validationSchema: zoekschermSchema
})(Zoekscherm);
