/* eslint react/prop-types: 0 */

import {
  createSpanWithId,
  DataGrid,
  useRequestInit,
  SettingsContext,
  convertAndFormatUtcToTimeZone
} from "adviesbox-shared";
import classnames from "classnames";
import { useFormikContext } from "formik";
import React, { ReactElement, useEffect, useRef, useContext } from "react";
import { Modal } from "react-bootstrap";
import isEqual from "react-fast-compare";
import { RouteComponentProps, withRouter } from "react-router";
import classes from "./selected-client.module.scss";
import { ZoekschermState } from "../infra/zoekscherm-schema";
import { DossierSamenvatting } from "../../.generated/hdndossier/hdndossiertypes";
import { Column } from "react-table-6";
import { getStatus } from "../infra/map-sx-status";
import useAbortableFetch from "use-abortable-fetch";
import { AdviesdossierOutput } from "../../.generated/forms/formstypes";

type SelectedClientModalProps = {
  setModalOpen: (val: boolean) => void;
  isModalOpen: boolean;
};

const getSoortAanvraag = (input: string): string => {
  if (input === "AX") return "AX Hypotheek";
  if (input === "LX") return "LX Leven";
  return input;
};

const SelectedClientModal = ({
  history,
  setModalOpen,
  isModalOpen
}: SelectedClientModalProps & RouteComponentProps): ReactElement => {
  const { values, setFieldValue } = useFormikContext<ZoekschermState>();
  const settings = useContext(SettingsContext);
  const { params, requestInit } = useRequestInit();
  const selectedClient = values.zoeken.selectedClient;
  const zoekresultaten = values.zoeken.zoekresultaten;
  const prevSelectedAdviesDossier = useRef(selectedClient?.hdnDossiers);
  const adviesdossierId = values.zoeken.zoekresultaten[0]?.hdnDossiers[0]?.adviesdossierId;
  const adviesdossierUrl = adviesdossierId
    ? `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${adviesdossierId}`
    : null;
  const adviesdossier = useAbortableFetch<AdviesdossierOutput>(adviesdossierUrl, requestInit);
  const adviesdossierOmschrijving =
    typeof adviesdossier.data === "string" ? "" : adviesdossier?.data?.adviesdossier?.korteOmschrijving ?? "";

  /* istanbul ignore next */
  useEffect(() => {
    if (
      selectedClient?.vestigingId &&
      selectedClient?.hdnDossiers &&
      !isEqual(prevSelectedAdviesDossier.current, selectedClient?.hdnDossiers)
    ) {
      prevSelectedAdviesDossier.current = selectedClient?.hdnDossiers;
    }
  }, [prevSelectedAdviesDossier, selectedClient]);

  const closeSelectedClientModal = (): void => {
    /* istanbul ignore next */ if (!selectedClient) return;
    const relevantZoekresultaat = zoekresultaten.find(kd => kd.klantdossierId === selectedClient.klantdossierId);
    /* istanbul ignore next */ if (!relevantZoekresultaat) return;
    const index = zoekresultaten.indexOf(relevantZoekresultaat);
    zoekresultaten.splice(index, 1, { ...selectedClient });
    setFieldValue("zoeken.selectedClient", null);
    setFieldValue("zoeken.zoekresultaten", [...zoekresultaten]);
    setModalOpen(false);
  };

  const hdnDossiersRowProps = (
    hdnDossier: DossierSamenvatting
  ): {
    onClick: () => void;
  } => ({
    onClick: (): void => {
      /* istanbul ignore next */
      if (!hdnDossier.hdnDossierId) return;
      history.push(`/vestiging/${params.vestiging}/dossier/${hdnDossier.hdnDossierId}/info`);
    }
  });

  const clientColumns: Column[] = [
    {
      Header: "Laatste bericht",
      id: "laatstBerichtDatum",
      Cell: (c): ReactElement => {
        var laatsteBerichtDt =
          c.original.laatstOntvangenBericht?.datum ?? /* istanbul ignore next */ c.original.startDatum;
        /* istanbul ignore else */
        if (laatsteBerichtDt) {
          laatsteBerichtDt = convertAndFormatUtcToTimeZone(laatsteBerichtDt);
        }

        return createSpanWithId(c.index, 0, laatsteBerichtDt, laatsteBerichtDt, "selected-client");
      }
    },
    {
      Header: "Omschrijving advies",
      id: "omschrijving",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          1,
          adviesdossierOmschrijving || /* istanbul ignore next */ "-",
          adviesdossierOmschrijving || /* istanbul ignore next */ "-",
          "selected-client"
        )
    },
    {
      Header: "HDN dossiernummer",
      id: "hdndossiernummer",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 1, c.original.hdnDossierId || "-", c.original.hdnDossierId || "-", "selected-client")
    },
    {
      Header: "Soort aanvraag",
      id: "soortAanvraag",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          1,
          getSoortAanvraag(c.original.soortAanvraag) || /* istanbul ignore next */ "-",
          getSoortAanvraag(c.original.soortAanvraag) || /* istanbul ignore next */ "-",
          "selected-client"
        )
    },
    {
      Header: "Partij",
      id: "partij",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          1,
          c.original.partijnaam || /* istanbul ignore next */ "-",
          c.original.partijnaam || /* istanbul ignore next */ "-",
          "selected-client"
        )
    },
    {
      Header: "Status",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          3,
          getStatus(c.original, c.original.afhandeldDoorServiceprovider, c.original.softwareKoppeling),
          getStatus(c.original, c.original.afhandeldDoorServiceprovider, c.original.softwareKoppeling),
          "selected-client"
        )
    }
  ];

  return (
    <Modal show={isModalOpen} backdrop={"static"} size={"lg"}>
      {selectedClient && (
        <div>
          <div className="px-4 pb-2 pt-4 d-flex">
            <h4>
              Dossiers van {selectedClient.hdnDossiers[0]?.klantNaam}
              {selectedClient.naamPartner ? "\xa0en\xa0" + selectedClient.naamPartner : ""}
            </h4>
            <div className={"ml-auto"}>
              <button
                type="button"
                className={"close"}
                onClick={closeSelectedClientModal}
                id={"close-selected-client-modal-icon"}
              >
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
          </div>
          <div className="px-4 pb-4">
            {adviesdossier.loading && <div className="loader"></div>}
            {selectedClient.hdnDossiers && selectedClient.hdnDossiers.length > 0 && !adviesdossier.loading && (
              <DataGrid
                name="zoeken.selectedClient.hdnDossiers"
                className={`search-table`}
                sortable={false}
                showPagination={false}
                minRows={selectedClient.hdnDossiers.length < 10 ? selectedClient.hdnDossiers.length : 10}
                getTdProps={(_state: any, row: any, col: any): { onClick: () => void } => {
                  return hdnDossiersRowProps(row.original);
                }}
                columns={clientColumns}
              />
            )}
            {(!selectedClient.hdnDossiers || selectedClient.hdnDossiers.length < 1) && (
              <div className="mb-4 mt-3">
                <h4 className="text-center w-100">
                  Er zijn nog geen dossiers van {selectedClient.naamAanvrager}
                  {selectedClient.naamPartner ? "\xa0en\xa0" + selectedClient.naamPartner : ""}
                </h4>
                <span className="d-block text-center w-100">
                  Gebruik onderstaande knop om een nieuw dossier aan te maken.
                </span>
              </div>
            )}
          </div>

          <div className={classnames("d-flex justify-content-end px-3", classes.modal_footer)}>
            <button
              id={"close-selected-client-modal-button"}
              data-testid="close-selected-client-modal-button"
              className="btn btn-link"
              onClick={closeSelectedClientModal}
            >
              Sluiten
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default withRouter(SelectedClientModal);
