import { createSpanWithId } from "adviesbox-shared";
import { ReactElement } from "react";
import { Column } from "react-table-6";
import moment from "moment";
import { convertAndFormatUtcToTimeZone } from "adviesbox-shared";

export const ZoekResultatenColumns = (): Column[] => {
  return [
    {
      headerStyle: { overflow: "initial" },
      Header: "Startdatum",
      accessor: "startdatum",
      id: "startdatum",
      maxWidth: 140,
      Cell: (c): ReactElement => {
        var startDate = "";
        if (c.original.hdnDossiers[0]?.startDatum) {
          startDate = convertAndFormatUtcToTimeZone(c.original.hdnDossiers[0].startDatum) || "";
        }

        return createSpanWithId(c.index, 0, startDate, startDate || "", "zoekresultaat");
      }
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Klant",
      accessor: "klant",
      id: "klantNaam",
      maxWidth: 140,
      Cell: (c): ReactElement => {
        return createSpanWithId(
          c.index,
          1,
          c.original.hdnDossiers[0].klantNaam,
          c.original.hdnDossiers[0].klantNaam,
          "zoekresultaat"
        );
      }
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Geboortedatum",
      accessor: "geboorteData",
      id: "geboorteData",
      maxWidth: 120,
      Cell: (c): ReactElement => {
        const aanvrager1 = c.original.geboorteData[0]
          ? moment(c.original.geboorteData[0].toJSON()).format("DD-MM-YYYY")
          : "";
        const aanvrager2 = c.original.geboorteData[1]
          ? moment(c.original.geboorteData[1].toJSON()).format("DD-MM-YYYY")
          : "";
        return createSpanWithId(
          c.index,
          3,
          `${aanvrager1}${aanvrager2 ? ", " + aanvrager2 : ""}`,
          `${aanvrager1}${aanvrager2 ? ", " + aanvrager2 : ""}`,
          "zoekresultaat"
        );
      }
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Postcode",
      accessor: "postcode",
      id: "postcode",
      maxWidth: 100,
      Cell: (c): ReactElement => createSpanWithId(c.index, 4, c.original.postcode, c.original.postcode, "zoekresultaat")
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Woonplaats",
      accessor: "woonplaats",
      id: "woonplaats",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 5, c.original.woonplaats, c.original.woonplaats, "zoekresultaat")
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Dossiers",
      accessor: "aantalDossiers",
      id: "aantalDossiers",
      maxWidth: 100,
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 6, c.original.aantalDossiers, c.original.aantalDossiers, "zoekresultaat")
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Adviseur",
      accessor: "adviseur",
      id: "adviseur",
      Cell: (c): ReactElement => {
        return createSpanWithId(
          c.index,
          6,
          c.original.hdnDossiers[0].adviseur,
          c.original.hdnDossiers[0].adviseur,
          "zoekresultaat"
        );
      }
    }
  ];
};
