import { HdnBericht, SoftwareKoppelingOptions } from "../../.generated/hdndossier/hdndossiertypes";

export const getStatusFromSx = (statusMelding: string): string => {
  const regex = /^(\d+)\s+(.+)/;
  const matches = statusMelding.match(regex);
  return matches && matches.length > 1 ? matches[2] : "status-onbekend";
};

export const getStatus = (hdnBericht: HdnBericht, afhandeldDoorServiceprovider: boolean, softwareKoppeling: SoftwareKoppelingOptions | null): string => {
  if (!hdnBericht) 
    return "";
  if ((afhandeldDoorServiceprovider && hdnBericht.status === "Verzonden") ||
      (softwareKoppeling && hdnBericht.status === "Aangeboden"))
    return "Aanvraag extern afgehandeld";
  if (hdnBericht.berichtType === "SX" && hdnBericht.statusMelding)  
    return getStatusFromSx(hdnBericht.statusMelding);

  return hdnBericht.status ? hdnBericht.status.toString() : "";
};
