import * as Yup from "yup";
import { InferType } from "yup";
import { yupEnum } from "../../shared/utils/yup-enum";
import { nullableLocalDateSchema } from "adviesbox-shared";
import { HdnDocumentStatus } from "../../.generated/hdndossier/hdndossiertypes";

export const binnengekomenDocument = Yup.object().shape({
    datum: nullableLocalDateSchema,
    hdnDocumentId: Yup.string()
        .nullable()
        .default(null),
    omschrijving: Yup.string()
        .nullable()
        .default(null),
    documentSoort: Yup.string()
        .nullable()
        .default(null),
    bestandsNaam: Yup.string()
        .nullable()
        .default(null),
    status: yupEnum(HdnDocumentStatus)
})

export const gevraagdDocument = Yup.object().shape({
    aanleverenVoorDatum: nullableLocalDateSchema,
    aanleverenVoorMoment: Yup.string()
        .nullable()
        .default(null),
    laatsteWijziging: nullableLocalDateSchema,
    kenmerk: Yup.string()
        .nullable()
        .default(null),
    mijnNr: Yup.string()
        .nullable()
        .default(null),
    bronNr: Yup.string()
        .nullable()
        .default(null),
    betreft: Yup.string()
        .nullable()
        .default(null),
    opgevraagdViaBron: Yup.boolean()
        .nullable()
        .default(false),
    hdnDocumentId: Yup.string()
        .nullable()
        .default(null),
    omschrijving: Yup.string()
        .nullable()
        .default(null),
    documentSoort: Yup.string()
        .nullable()
        .default(null),
    bestandsNaam: Yup.string()
        .nullable()
        .default(null),
    status: yupEnum(HdnDocumentStatus)
})

export const documentenSchema = Yup.object().shape({
    binnengekomenDocumenten: Yup.array(binnengekomenDocument)
        .default([]),
    gevraagdeDocumenten: Yup.array(gevraagdDocument)
        .default([]),
    aangeleverdeDocumenten: Yup.array(gevraagdDocument) // Gebruikt ook model: HdnGevraagdDocument
        .default([]),
})

export type DocumentenSchemaType = InferType<typeof documentenSchema>;
export type DocumentenProps = InferType<typeof documentenSchema>;