import {
  LoginPage,
  SettingsContext,
  SigninOidc,
  getAppLocation,
  getCookie,
  getOTAP,
  getPathname
} from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import logo from "../assets/new-brand-logo.svg";
import { RouteParams } from "adviesbox-shared/utils/types";

const UnauthenticatedHdnDossierApp = (): ReactElement => {
  const { baseUrls } = useContext(SettingsContext);
  const linkToPortal = getAppLocation(window.location.origin, "HDN", "POR", baseUrls);
  const vestigingIdCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);

  return (
    <>
      <Route
        exact
        path={"/vestiging/:vestiging"}
        render={({ history }: RouteComponentProps<RouteParams>) => {
          let pathName = getPathname();
          if (!pathName.endsWith("/")) pathName = pathName + "/";
          history.push(`${pathName}zoeken`);
          return <></>;
        }}
      />
      <Route
        exact
        path={"/"}
        render={({ history }: RouteComponentProps) => {
          if (!vestigingIdCookie) {
            window.location.assign(linkToPortal);
            return <></>;
          }

          history.push(`/vestiging/${vestigingIdCookie}/zoeken`);
          return <></>;
        }}
      />
      <Route path="/signin-oidc" component={SigninOidc} />
      <LoginPage appLogo={logo} />
    </>
  );
};

UnauthenticatedHdnDossierApp.displayName = "UnauthenticatedHdnDossierApp";

export default UnauthenticatedHdnDossierApp;
