import { SettingsType, hasJSONResponse, validationResultsToUiError } from "adviesbox-shared";
import { User } from "oidc-client";
import { DossierSchemaType } from "./infra/dossier-schema";
import { Data, UiError } from "../shared/types";
import { mapDossierDlTargetToUiField } from "./infra/map-dossier-dl-target-to-ui-field";
import { FormikContextType } from "formik";

export const dossierOpslaan = async (
  settings: SettingsType,
  user: User,
  vestigingId: string,
  dossierId: string,
  formik: FormikContextType<DossierSchemaType>
): Promise<UiError[] | null> => {
  const url = `${settings.hdnDossierOrigin}/dossier/${dossierId}/status/${formik.values.status}`;
  const rsp = await fetch(url, {
    method: "PUT",
    headers: {
      authorization: `${user.token_type} ${user.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    },
  });

 if (!(rsp.ok) && hasJSONResponse(rsp)) {
    const data: Data = await rsp.json();
    if (data && data.validationResults) {
      return validationResultsToUiError(data.validationResults, mapDossierDlTargetToUiField);
    }
  }

  if (rsp.ok && rsp.status === 204) {
    return null;
  }
  
  if (!(rsp.ok && hasJSONResponse(rsp))) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
  };

  const json = await rsp.json();

  if (!json.isValid) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);
  }
  return json;
};