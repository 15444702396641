/* istanbul ignore file */
import { ReactElement } from "react";
import { ValidationResultModel } from "adviesbox-shared";
import adresSchema from "./generic-parts/adres/schema";
import * as Yup from "yup";
import {
  KlantnaamReadOnly,
  FinancieringOptions,
  Dossierstatus,
  GeslachtOpties
} from "../.generated/hdndossier/hdndossiertypes";

export type LabelValuePairs = LabelValuePair[];
export type LabelValuePair = {
  label: string;
  value: string;
  tooltip?: string | ReactElement;
  description?: string[];
};

export enum AanvragerKeuze {
  Beiden = "Beiden",
  Aanvrager1 = "Aanvrager1",
  Aanvrager2 = "Aanvrager2"
}

export const JaNeeOptions = [
  { label: "Ja", value: "true" },
  { label: "Nee", value: "false" }
];

export enum Direction {
  Vertical,
  Horizontal
}

export type UiName = {
  field: string | null;
  label: string | null;
};

export type UiError = UiName & {
  error: string;
};

export type AdresType = Yup.InferType<typeof adresSchema>;

export type Data = {
  isValid?: boolean | null;
  message?: string | null;
  validationResults?: ValidationResultModel[] | null;
};

export declare type DossierRouteParams = {
  vestiging: string;
  dossierId: string;
};

type primitives = string | number | boolean | undefined | null;
type buildin = Date | RegExp;

type RemoveArray<T> = T extends (infer U)[] ? U : T;
export type FieldMap<T, N = { field: string; label: string }> = {
  [P in keyof T]?: T[P] extends primitives | buildin
    ? N
    : FieldMap<RemoveArray<T[P]>> & { field?: string; label?: string };
};

/** Bevat adresgegevens. */
export interface Adres {
  /** Straatnaam */
  straat: string | null;
  /** Huisnummer (het numerieke deel van het huisnummer) */
  huisnummer: number | null;
  /** Huisnummer toevoeging (het niet-numerieke deel van het huisnummer) */
  toevoeging: string | null;
  /** Postcode */
  postcode: string | null;
  /** De locatieaanduiding. Wordt veelal gebuikt bij bedrijfscomplexen (bijv. toren II). */
  locatie: string | null;
  /** Plaatsnaam */
  plaats: string | null;
  /** Land (identificatie) */
  landCode: string | null;
}

export interface AdresReadOnly {
  /** Straatnaam */
  readonly straat: string | null;
  /** Huisnummer (het numerieke deel van het huisnummer) */
  readonly huisnummer: number | null;
  /** Huisnummer toevoeging (het niet-numerieke deel van het huisnummer) */
  readonly toevoeging: string | null;
  /** Postcode */
  readonly postcode: string | null;
  /** De locatieaanduiding. Wordt veelal gebuikt bij bedrijfscomplexen (bijv. toren II). */
  readonly locatie: string | null;
  /** Plaatsnaam */
  readonly plaats: string | null;
  /** Land (identificatie) */
  readonly landCode: string | null;
}

/** Een regel uit het resultaat van het zoeken naar adviesdossiers, inclusief gegevens van de aanvrager(s). */
export interface ZoekenAdviesdossierMetKlantInfo {
  aanvrager1: KlantnaamReadOnly | null;
  aanvrager2: KlantnaamReadOnly | null;
  /** Unieke identificatie van een gevonden adviesdossier. */
  adviesdossierId: string;
  /** Datum dat het adviesdossier voor het laatst bewerkt is. */
  readonly laatstBewerktDatum: string | null;
  /** Tijd dat het adviesdossier voor het laatst bewerkt is. */
  laatstBewerktTijd: string | null;
  aanleiding: FinancieringOptions | null;
  /** Korte omschrijving van het adviesdossier. */
  readonly omschrijving: string | null;
  status: Dossierstatus;
  bemiddelingDossierStatus: Dossierstatus | null;
  /** De ids van de adviseurs gekoppeld aan dit adviesdossier */
  adviseurIds: string[] | null;
}

/** Het resultaat van een zoekopdracht naar recent geopende adviesdossiers. */
export interface RecentGeopendOutput {
  /** De gevonden resultaten. */
  readonly zoekresultaten: ZoekenAdviesdossierMetKlantInfo[] | null;
  isValid: boolean;
  message: string | null;
  validationResults: ValidationResultModel[] | null;
}

export interface KlantgegevensReadOnly {
  readonly straat: string | null;
  readonly huisnummer: number | null;
  readonly toevoeging: string | null;
  readonly postcode: string | null;
  readonly plaats: string | null;
  readonly telefoonWerk: string | null;
  readonly telefoonMobiel: string | null;
  readonly telefoonPrive: string | null;
  readonly email: string | null;
  klantId: string;
  readonly achternaam: string | null;
  readonly voorvoegsel: string | null;
  readonly voorletters: string | null;
  readonly voornamen: string | null;
  geslacht: GeslachtOpties | null;
  readonly roker: boolean | null;
  readonly geboortedatum: string | null;
  readonly aowdatum: string | null;
}
