import React, { ReactElement, useContext, useState } from "react";
import { connect, FormikContextType, useFormikContext } from "formik";

import { withRouter, RouteComponentProps } from "react-router";
import {
  SelectInput,
  FilterType,
  SortType,
  DataGrid,
  useAdviesboxDataRepository,
  SettingsContext
} from "adviesbox-shared";
import classes from "./laatste-dossiers.module.scss";
import ParamRouteContext from "../../shared/paramrouting/paramrouting-context";
import { laatsteDossierColumns } from "./laatste-dossiers-columns";
import { ZoekschermState, LaatsteDossierType, laatsteDossiersSchema } from "../infra/zoekscherm-schema";
import { mapZoekschermDlToUi } from "../infra/map-zoekscherm";
import { UiName } from "../../shared/types";
import { DossierSamenvattingOutput } from "../../.generated/hdndossier/hdndossiertypes";

const LaatsteDossiers = ({
  showDossiers,
  history
}: {
  formik: FormikContextType<ZoekschermState>;
  showDossiers: boolean;
} & RouteComponentProps): ReactElement => {
  const { values } = useFormikContext<ZoekschermState>();
  const { vestiging } = useContext(ParamRouteContext);
  const [gegevensOpgehaald, setGegevensOpgehaald] = useState(false);

  const settings = useContext(SettingsContext);
  const url = `${settings.hdnDossierOrigin}/dossier?limiet=20`;

  /* istanbul ignore next */
  const { fetchData, loading } = useAdviesboxDataRepository<DossierSamenvattingOutput, ZoekschermState>(url, {
    mapDlToUi: (_, res) => {
      if (res) {
        res.isValid = true;
        const mappedResults = mapZoekschermDlToUi(res);
        const zoekschermData: ZoekschermState = {
          ...values,
          laatsteDossiers: mappedResults?.laatsteDossiers ?? laatsteDossiersSchema.default()
        };
        return zoekschermData;
      }
    },
    mapTargetToUiField: (): UiName | null => {
      return null;
    }
  });

  if (showDossiers && !gegevensOpgehaald) {
    fetchData();
    setGegevensOpgehaald(true);
  }

  const rowProps = (
    dossier: LaatsteDossierType
  ): {
    onClick: () => void;
  } => ({
    /* istanbul ignore next */
    onClick: (): void => {
      history.push(`/vestiging/${vestiging}/dossier/${dossier.hdndossierId}/info`);
    }
  });

  return (
    <>
      {loading && <div className="loader"></div>}

      {!loading && (
        <>
          {showDossiers && values.laatsteDossiers.laatsteDossiers && !!values.laatsteDossiers.laatsteDossiers.length && (
            <div data-testid="test-table" className={classes.table_container}>
              <DataGrid
                name={"laatsteDossiers.laatsteDossiers"}
                className="search-table"
                sortable={true}
                filterable={true}
                filterConfig={{
                  hdndossierId: FilterType.Checkbox,
                  klantNaam: FilterType.Text,
                  soortAanvraag: FilterType.Checkbox
                }}
                sortConfig={{
                  laatstGeopend: SortType.Descending
                }}
                initialSort="laatstGeopend"
                minRows={0}
                loading={false}
                resizable={false}
                columns={laatsteDossierColumns}
                showPagination={true}
                pageSize={values.laatsteDossiers.inView}
                getTrProps={(
                  _state: any,
                  rowInfo: any
                ): {
                  onClick: () => void;
                } => rowProps(rowInfo.original)}
                PaginationComponent={(): ReactElement => (
                  <div className={"d-flex mt-3 px-2"}>
                    <span className={classes.mt_02}>Laatste</span>
                    <SelectInput
                      className={"mx-2 px-0"}
                      fieldSize={"s"}
                      name={"laatsteDossiers.inView"}
                      options={[
                        { label: "10", value: "10" },
                        { label: "15", value: "15" },
                        { label: "20", value: "20" }
                      ]}
                    />
                    <span className={classes.mt_02}>geopende dossiers tonen</span>
                  </div>
                )}
              />
            </div>
          )}
          {showDossiers &&
            (!values.laatsteDossiers.laatsteDossiers || values.laatsteDossiers.laatsteDossiers.length === 0) && (
              <div className={`d-flex flex-column align-items-center justify-content-center ${classes.no_results}`}>
                <h2 className={classes.no_results_title}>Er zijn geen laatst geopende dossiers</h2>
                {!values.recentGeopendError && (
                  <span className={classes.no_results_sub}>
                    Zoek een dossier of klant met bovenstaande balk of maak een nieuwe klant aan met bovenstaande knop.
                  </span>
                )}
                {values.recentGeopendError && (
                  <span className={classes.no_results_sub}>
                    Er gaat iets mis met het ophalen van je laatst geopende dossiers, probeer het later opnieuw.
                  </span>
                )}
              </div>
            )}
        </>
      )}
    </>
  );
};

export default connect<{ showDossiers: boolean }, ZoekschermState>(withRouter(LaatsteDossiers));
