/* istanbul ignore file */
import { PageLoading, SettingsType, withErrorBoundary, ErrorPage, InvalidAdviesboxResultErrorPage } from "adviesbox-shared";
import React, { ReactElement, useCallback } from "react";
import { DossierRouteParams } from "../shared/types";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { mapDossierDlNaarUi } from "./infra/map-dossier-dl-to-ui";
import { mapDossierDlTargetToUiField } from "./infra/map-dossier-dl-target-to-ui-field";
import Dossier from "./dossier";

const DossierAjax = (): ReactElement => {
  const createUrl = useCallback((s: SettingsType, p: DossierRouteParams): string => {
      return `${s.hdnDossierOrigin}/dossier/${p.dossierId}`;
    },
    []
  );

  const { loading, error, data, platformData } = useAdviesboxData(
    createUrl,
    p => p.dossierId,
    mapDossierDlNaarUi,
    () => {},
    mapDossierDlTargetToUiField,
  );

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <Dossier saveData={async () => null} {...data} />;
};

DossierAjax.displayName = "DossierAjax";
export default withErrorBoundary(DossierAjax);
