import { LocalDate } from "@js-joda/core";
import { CellInfo } from "react-table-6";
import * as Yup from "yup";
import { InferType } from "yup";
import {
  GeslachtOpties,
  ZoekResultaatSorteerOptions,
  HdnBericht,
  HdnDossierStatus,
  SoftwareKoppelingOptions
} from "../../.generated/hdndossier/hdndossiertypes";
import adresSchema from "../../shared/generic-parts/adres/schema";
import { localDateSchema, localDateTimeSchema, nullableLocalDateSchema } from "adviesbox-shared";
import { hasValue } from "../../shared/utils/helpers";
import { yupEnum } from "../../shared/utils/yup-enum";
import { birthdate2age } from "../../shared/utils/birthdate-to-age";

export const regExpStringForGuid = "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$";

export enum SearchCategoriesAdvies {
  Naam = "Naam",
  Postcode = "Postcode",
  Woonplaats = "Woonplaats",
  Geboortedatum = "Geboortedatum",
  Dossiernummer = "Dossiernummer"
}

export enum AdviesDossierStatus {
  Geen = "Geen",
  InBehandeling = "InBehandeling",
  Actief = "Actief",
  NietActief = "NietActief"
}
export const zoekenAdviesdossierSchema = Yup.object({
  klantdossierId: Yup.string(),
  adviesdossierId: Yup.string(),
  laatstBewerktDatum: Yup.string().nullable(),
  laatstBewerktTijd: Yup.string().nullable(),
  geboorteData: Yup.string().nullable(),
  postcode: Yup.string().nullable(),
  woonplaats: Yup.string().nullable(),
  vestiging: Yup.string().nullable(),
  vestigingId: Yup.string().nullable(),
  aanleiding: Yup.string().nullable(),
  omschrijving: Yup.string(),
  status: Yup.string().nullable(),
  hdnDossierId: Yup.string().nullable()
});

export const hdnDossierSamenvattingSchema = Yup.object({
  hdnDossierId: Yup.string(),
  adviesdossierId: Yup.string(),
  adviesOmschrijving: Yup.string().nullable(),
  klantNaam: Yup.string().nullable(),
  laatstOntvangenBericht: Yup.mixed<HdnBericht>().nullable(),
  status: Yup.mixed<HdnDossierStatus>(),
  aanvraagVersie: Yup.number(),
  soortAanvraag: Yup.string().nullable(),
  startDatum: Yup.string().nullable(),
  partijnaam: Yup.string().nullable(),
  adviseur: Yup.string().nullable(),
  product: Yup.string().nullable()
});

const aanvragerSchema = Yup.object({
  klantId: Yup.string().default(""),
  voornaam: Yup.string().default(""),
  tussenVoegsel: Yup.string()
    .nullable()
    .default(null),
  geboorteDatum: localDateSchema
    .typeError("Vul alsjeblieft een geldige geboortedatum in.")
    .test({
      message: "Vul alsjeblieft een geldige datum in.",
      test: (value: LocalDate | null) => !hasValue(value) || value.year() >= 1900
    })
    .test({
      message: "Vul alsjeblieft een geldige geboortedatum in.",
      test: (value: LocalDate | null) => !hasValue(value) || value < LocalDate.now()
    })
    .test({
      message: "De aanvrager moet minimaal 18 jaar oud zijn.",
      test: (value: LocalDate | null) => !hasValue(value) || birthdate2age(value) >= 18
    })
    .test({
      message: "Vul alsjeblieft de geboortedatum in.",
      test: (value: LocalDate | null) => hasValue(value)
    }),
  achternaam: Yup.string()
    .default("")
    .required("Vul alsjeblieft de achternaam in."),
  geslacht: Yup.mixed<GeslachtOpties>()
    .oneOf(Object.values(GeslachtOpties))
    .nullable()
    .default(GeslachtOpties.Geen)
});

const partnerSchema = Yup.object({
  klantId: Yup.string().default(""),
  voornaam: Yup.string().default(""),
  tussenVoegsel: Yup.string()
    .nullable()
    .default(null),
  geboorteDatum: localDateSchema
    .typeError("Ongeldige datum.")
    .test({
      message: "Vul alsjeblieft een geldige datum in.",
      test: (value: LocalDate | null) => !hasValue(value) || value.year() >= 1900
    })
    .test({
      message: "Vul alsjeblieft een geldige geboortedatum in.",
      test: (value: LocalDate | null) => !hasValue(value) || value < LocalDate.now()
    })
    .test({
      message: "De aanvrager moet minimaal 18 jaar oud zijn.",
      test: (value: LocalDate | null) => !hasValue(value) || birthdate2age(value) >= 18
    })
    .test({
      message: "Vul alsjeblieft de geboortedatum in.",
      test: (value: LocalDate | null) => hasValue(value)
    }),
  achternaam: Yup.string()
    .default("")
    .required("Vul alsjeblieft de achternaam in."),
  geslacht: Yup.mixed<GeslachtOpties>()
    .oneOf(Object.values(GeslachtOpties))
    .nullable()
    .default(GeslachtOpties.Geen)
});

export const zoekResultaatClientSchema = Yup.object({
  klantdossierId: Yup.string(),
  laatstGeopend: localDateTimeSchema.nullable(),
  klant: Yup.string(),
  aantalDossiers: Yup.number(),
  adviseur: Yup.string(),
  adviseurId: Yup.string().nullable(),
  vestiging: Yup.string(),
  geboorteData: Yup.array(nullableLocalDateSchema.nullable()).default([]),
  postcode: Yup.string().nullable(),
  woonplaats: Yup.string().nullable(),
  vestigingId: Yup.string().nullable(),
  adviesDossiers: Yup.array(zoekenAdviesdossierSchema),
  naamAanvrager: Yup.string(),
  naamPartner: Yup.string().nullable(),
  hdnDossiers: Yup.array(hdnDossierSamenvattingSchema)
});

export const zoekenSchema = Yup.object({
  vestiging: Yup.string().default("ALL"),
  optieDossiers: Yup.string().default("0"),
  sorteren: yupEnum(ZoekResultaatSorteerOptions).default(ZoekResultaatSorteerOptions.AchternaamOplopend),
  geboortedatum: nullableLocalDateSchema,
  woonplaats: Yup.string().nullable(),
  postcode: Yup.string().nullable(),
  dossierNummer: Yup.string()
    .nullable()
    .test({
      test: function(value: any) {
        if (
          hasValue(this.parent?.dossierNummer) &&
          (!!this.parent?.geboortedatum ||
            !!(this.parent?.postcode || !!this.parent?.woonplaats || !!this.parent.searchValue))
        ) {
          return this.createError({
            message: "Dossiernummer kan niet gecombineerd worden met andere zoekvelden"
          });
        }

        if (value && !value.match(regExpStringForGuid)) {
          return this.createError({
            message: "Voer een correct dossiernummer (GUID) in."
          });
        }
        return true;
      }
    }),
  searchValue: Yup.string().default(""),
  searching: Yup.boolean().default(false),
  zoekresultaten: Yup.array(zoekResultaatClientSchema).default([]),
  zoekresultatenMetToegangsrechten: Yup.array(zoekResultaatClientSchema).default([]),
  selectedClient: zoekResultaatClientSchema.nullable().default(null),
  soortAanvraag: Yup.string().default("AX")
});

export const laatsteDossierSchema = Yup.object({
  adviesdossierId: Yup.string(),
  afhandeldDoorServiceprovider: Yup.boolean(),
  softwareKoppeling: Yup.mixed<SoftwareKoppelingOptions>()
    .oneOf(Object.values(SoftwareKoppelingOptions))
    .nullable()
    .default(null),
  hdndossierId: Yup.string(),
  laatstOntvangenBericht: Yup.mixed<HdnBericht>()
    .nullable()
    .default(null),
  soortAanvraag: Yup.string(),
  status: Yup.string(),
  startDatum: Yup.string(),
  klantNaam: Yup.string(),
  adviesOmschrijving: Yup.string(),
  aanvraagVersie: Yup.number()
});

export const laatsteDossiersSchema = Yup.object({
  aantalDossiers: Yup.number().default(0),
  laatsteDossiers: Yup.array(laatsteDossierSchema).default([]),
  inView: Yup.number().default(10)
});

export const nieuweKlantSchema = Yup.object({
  aanvrager1: aanvragerSchema,
  aanvrager2: Yup.object<InferType<typeof aanvragerSchema>>()
    .when("hasPartner", {
      is: true,
      then: partnerSchema
    })
    .default(partnerSchema.default()),
  hasPartner: Yup.boolean().default(false)
});

export const zoekschermSchema = Yup.object({
  zoeken: zoekenSchema,
  laatsteDossiers: laatsteDossiersSchema,
  nieuweKlant: nieuweKlantSchema,
  recentGeopendError: Yup.boolean().default(false),
  totalNumberOfPages: Yup.number().default(0),
  totalNumberOfResults: Yup.number().default(0)
});

export type ZoekschermState = InferType<typeof zoekschermSchema>;
export type ZoekenType = InferType<typeof zoekenSchema>;
export type ZoekResultaatType = InferType<typeof zoekResultaatClientSchema>;
export type ZoekenAdviesDossierType = InferType<typeof zoekenAdviesdossierSchema>;
export type ZoekenHdnDossierSamenvattingType = InferType<typeof hdnDossierSamenvattingSchema>;

export type LaatsteDossierType = InferType<typeof laatsteDossierSchema>;
export type LaatsteDossiersType = InferType<typeof laatsteDossiersSchema>;
export type NieuweKlantType = InferType<typeof nieuweKlantSchema>;

export enum TableColumnId {
  DeleteClientButton = "DeleteClientButton",
  DeleteDossierButton = "DeleteDossierButton",
  Aanleiding = "Aanleiding",
  Status = "Status",
  Vestiging = "Vestiging"
}

export type LogRequestBody = {
  username: string;
  datumTijdOpening: Date;
};

export type NieuwKlantRequestBody = {
  achternaamAanvrager1: string;
  tussenvoegselAanvrager1: string | null;
  roepnaamAanvrager1: string;
  geboortedatumAanvrager1: string;
  heeftAanvrager2: boolean;
  achternaamAanvrager2: string | null;
  tussenvoegselAanvrager2: string | null;
  roepnaamAanvrager2: string | null;
  geboortedatumAanvrager2: string | null;
};

export type DeleteClientButtonProps = {
  deleteClientFunc: (klantdossierId: string) => () => void;
  cellInfo: CellInfo;
};

export const vestigingSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  naam: Yup.string().default(""),
  isHoofdvestiging: Yup.boolean().default(false),
  waarschuwingsBericht: Yup.string()
    .default("")
    .nullable(),
  telefoonnummer: Yup.string().default(""),
  emailadres: Yup.string().default(""),
  bezoekadres: adresSchema,
  postadres: adresSchema.nullable().default(null),
  afwijkendPostadresTonen: Yup.boolean()
    .default(false)
    .nullable(),
  afmRegistratienummer: Yup.string().default(""),
  platformId: Yup.string()
    .nullable()
    .default(""),
  hasChanged: Yup.boolean().default(false),
  isDeleted: Yup.boolean().default(false),
  putChanges: Yup.boolean().default(false),
  postChanges: Yup.boolean().default(false),
  savedChanges: Yup.boolean().default(false),
  triggeredByModal: Yup.boolean().default(false)
});

export type VestigingType = Yup.InferType<typeof vestigingSchema>;

export const vestigingenSchema = Yup.object({
  vestigingen: Yup.array(vestigingSchema).default([])
});
export type VestigingenType = Yup.InferType<typeof vestigingenSchema>;
