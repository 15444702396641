/* istanbul ignore file */
import { UiName, FieldMap } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";
import { DocumentenOutput } from "../../.generated/hdndossier/hdndossiertypes";

export function mapDocumentenDlTargetToUiField(target: string): UiName | null {
  const map: FieldMap<DocumentenOutput> = {};

  return target2field(map, target);
}
