import { ValidatiePopup, BrowserNavigation, Icon } from "adviesbox-shared";
import classNames from "classnames";
import { FormikContextType } from "formik";
import React, {
  Dispatch,
  MutableRefObject,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { Button } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import { WithSaveData } from "../../utils/save-data";
import { isOkSubmitResult, submit, SubmitResultTypes } from "../../utils/save-validation";
import SaveNavigation from "../save-navigation/save-navigation";
import classes from "./SaveButton.module.scss";
import { saveButtonTextResources } from "./save-button-resources";
import { SaveFormContext } from "../save-form/save-form-context";

type SaveButtonProps = {
  callBack?: () => void;
  preSaveConfirmation?: (path?: string) => Promise<void> | void;
  triggerPreSaveConfirmation?: boolean;
  name?: string;
  initialSaveResult?: SubmitResultTypes; // Voor testen alleen
  setPreviousPath?: Dispatch<SetStateAction<string | null>>;
  context: FormikContextType<any> &
    WithSaveData<any> & {
      berekenSideEffectRef?: MutableRefObject<{ asyncResult: Promise<unknown> }>;
    };
  isCanceled?: boolean;
};

const SaveButtonComponent = ({
  history,
  context,
  context: { saveData, dirty, isSubmitting },
  callBack,
  preSaveConfirmation,
  triggerPreSaveConfirmation,
  setPreviousPath,
  isCanceled,
  ...props
}: SaveButtonProps & RouteComponentProps): ReactElement | null => {
  const { setIsSaving, version, setSaveResult: propagateSaveResult } = useContext(SaveFormContext);
  const [lastSave, setLastSave] = useState(version);
  let buttonContent: ReactElement | null = null;
  const [showValidatiePopup, setShowValidatiePopup] = useState(false);
  const [saveResult, setSaveResult] = useState<SubmitResultTypes>(
    props.initialSaveResult || /* istanbul ignore next */ "default"
  );
  const [showNavValidatiePopup, setNavValidatiePopup] = useState(false);
  const [prevStateValues, setPrevStateValues] = useState<any>(context.initialValues);

  const actualSaveData = useCallback(
    async (
      preventReloadNavigation?: boolean,
      skipTriggerPreSaveConfirmation?: boolean
    ): Promise<SubmitResultTypes | null> => {
      if (triggerPreSaveConfirmation && /* istanbul ignore next */ !skipTriggerPreSaveConfirmation) {
        preSaveConfirmation && (await preSaveConfirmation());
        return null;
      }

      const validationResult = await submit(
        saveData,
        context,
        context.berekenSideEffectRef,
        callBack,
        preventReloadNavigation
      );
      setSaveResult(validationResult);
      setShowValidatiePopup(!isOkSubmitResult(validationResult));
      return validationResult;
    },
    [triggerPreSaveConfirmation, preSaveConfirmation, saveData, context, callBack, setSaveResult, setShowValidatiePopup]
  );

  useEffect(() => {
    if (lastSave === version) return;
    setLastSave(version);
    setIsSaving(true);
    propagateSaveResult(null);
    actualSaveData()
      .then(values => {
        propagateSaveResult(values);
      })
      .finally(() => {
        setIsSaving(false);
      })
      .catch(reason => {
        /* eslint-disable-line no-console */ /* istanbul ignore next */ console.error(reason);
      });
  }, [
    lastSave,
    version,
    setIsSaving,
    triggerPreSaveConfirmation,
    preSaveConfirmation,
    saveData,
    context,
    callBack,
    setSaveResult,
    setShowValidatiePopup,
    propagateSaveResult,
    actualSaveData
  ]);

  useEffect(() => {
    if (prevStateValues !== context.values) {
      setPrevStateValues(context.values);
    }
  }, [callBack, context, prevStateValues, saveData, actualSaveData]);

  if (isSubmitting) {
    /* istanbul ignore next */ buttonContent = (
      <span id={props.name || "save-button"} className={classes.busy}>
        {saveButtonTextResources("Busy")}
      </span>
    );
  } else if (dirty) {
    const isPlatformError = saveResult === "platformError";
    buttonContent = (
      <Button
        id={props.name || "save-button"}
        variant={"primary"}
        onClick={async (): Promise<void> => {
          // trigger het opslaan
          setLastSave(version - 1);
        }}
      >
        <Icon name="save" className="mr-2" />
        {isPlatformError ? saveButtonTextResources("TryAgain") : saveButtonTextResources("Save")}
      </Button>
    );
  } else {
    buttonContent = (
      <span
        id={props.name || "save-button"}
        className={classNames({
          [classes.no_save]: saveResult === "default",
          [classes.saved]: saveResult === "completed"
        })}
      >
        {saveButtonTextResources("SaveSucces")}
      </span>
    );
  }

  return (
    <>
      <>
        <div className="text-container pl-3">
          <div className="save-btn-position">
            <div className="button-container">{buttonContent}</div>
          </div>
        </div>
        <SaveNavigation
          history={history}
          triggerPreSaveConfirmation={triggerPreSaveConfirmation}
          preSaveConfirmation={preSaveConfirmation}
          saveData={saveData}
          setValidatiePopup={setNavValidatiePopup}
          setSaveResultText={setSaveResult}
          showValidatiePopup={showNavValidatiePopup}
          callBack={callBack}
          setPreviousPath={setPreviousPath}
          isCanceled={isCanceled}
        />
        <ValidatiePopup
          infotekst={saveResult}
          show={showValidatiePopup}
          onHide={(): void => setShowValidatiePopup(false)}
        />
        <BrowserNavigation
          preventWarning={triggerPreSaveConfirmation || false}
          dirty={dirty}
          modalShow={showNavValidatiePopup}
        />
      </>
    </>
  );
};

SaveButtonComponent.displayName = "SaveButton";

export const SaveButton = withRouter(SaveButtonComponent);
