import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { DocumentenOutput, HdnBinnengekomenDocument, HdnGevraagdDocument } from "../../.generated/hdndossier/hdndossiertypes";
import { LocalDate } from "@js-joda/core";
import { DocumentenSchemaType, documentenSchema, gevraagdDocument, binnengekomenDocument } from "./documenten-schema";

export const mapBinnengekomenDocument = createMapToUi(binnengekomenDocument).from<HdnBinnengekomenDocument>({
    datum: v => LocalDate.parse(v.datum.slice(0, 10)),
    hdnDocumentId: v => v.hdnDocumentId,
    omschrijving: v => v.omschrijving,
    documentSoort: v => v.documentSoort,
    bestandsNaam: v => v.bestandsNaam,
    status: v => v.status
});

export const mapGevraagdeDocument = createMapToUi(gevraagdDocument).from<HdnGevraagdDocument>({
    aanleverenVoorDatum: v => v.aanleverenVoorDatum ? LocalDate.parse(v.aanleverenVoorDatum.slice(0, 10)) : null,
    aanleverenVoorMoment: v => v.aanleverenVoorMoment,
    laatsteWijziging: v => LocalDate.parse(v.laatsteWijziging.slice(0, 10)),
    kenmerk: v => v.kenmerk,
    mijnNr: v => v.mijNr,
    bronNr: v => v.bronNr,
    opgevraagdViaBron: v => v.opgevraagdViaBron,
    hdnDocumentId: v => v.hdnDocumentId,
    omschrijving: v => v.omschrijving,
    documentSoort: v => v.documentSoort,
    bestandsNaam: v => v.bestandsNaam,
    status: v => v.status,
    betreft: v => v.betreft
});


export const mapDossier = createMapToUi(documentenSchema).from<DocumentenOutput>({
    binnengekomenDocumenten: v => v.binnengekomenDocumenten?.map(entry => mapBinnengekomenDocument(entry)),
    gevraagdeDocumenten: v => v.gevraagdeDocumenten?.map(entry => mapGevraagdeDocument(entry)),
    aangeleverdeDocumenten: v => v.aangeleverdeDocumenten?.map(entry => mapGevraagdeDocument(entry))
});

export function mapDocumentenDlNaarUi(
    dossierId: string,
    data: DocumentenOutput
): DocumentenSchemaType | null {
    if (!dossierId) return null;
    return mapDossier(data);
}
