/* istanbul ignore file */
import { SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";

export const retryPost = async (
  settings: SettingsType,
  user: User | null,
  berichtId: string,
  vestigingId: string
) => {
  const url = `${settings.foundationOrigin}/HdnOnline/bus/RetryBericht/${berichtId}`;
  await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId: vestigingId ?? ""
    }
  });
};
