/* istanbul ignore file */

const resources = {
  ErrorHuisnummer: "Nummer mag niet groter zijn dan 32767.",
  ErrorPostcode: "Ongeldige postcode.",
  ErrorDatumToekomst: "Datum mag niet in de toekomst liggen.",
  ErrorMaxLengte: "Veld mag maximaal {lengte} lang zijn.",
  ErrorDatumLeeg: "Datum moet leeg zijn.",
  ErrorBedragPostief: "Dient een positief bedrag te zijn.",
  ErrorAantalJaren: "Vul alsjeblieft een getal in tussen de 0 en 99.",
  ErrorAantalMaanden: "Vul alsjeblieft een getal in tussen de 0 en 11 maanden.",
  NavigatiePromptTekst: "Weet je het zeker? Hier staat een tekst uit de resource file.. ?",
  ErrorOnbekendeWaarde: "Onbekende waarde",
  ErrorDatumVerplicht: "Vul alsjeblieft een datum in."
};

export type GeneralTextResources = typeof resources;

export function getGeneralTextResources(id: keyof GeneralTextResources): string {
  return resources[id];
}

export function createGetTextResources<T>(resources: T): (id: keyof T | keyof GeneralTextResources) => string {
  return function getTextResources(id: keyof T | keyof GeneralTextResources): string {
    if (id in resources) {
      return (resources as any)[id];
    }

    return getGeneralTextResources(id as keyof GeneralTextResources);
  };
}
