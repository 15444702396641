import React, { ReactElement } from "react";
import { connect, useFormikContext } from "formik";
import { DataGrid } from "adviesbox-shared";
import { DocumentenSchemaType } from "../infra/documenten-schema";
import { documentKolommen } from "../documenten-kolommen";

const ReedsAangeleverdeDocumenten = (): ReactElement => {
  const { setFieldValue, values } = useFormikContext<DocumentenSchemaType>();

  /* istanbul ignore next */
  const onDataGridChange = (data?: DocumentenSchemaType[]): void => {
    if (!data || !data.length) return;
    setFieldValue("aangeleverdeDocumenten", data);
  };

  return (
    <div className="m-2 pb-2">
      <DataGrid
        name={"aangeleverdeDocumenten"}
        showButtonAddRow={false}
        columns={documentKolommen}
        resizable={false}
        sortable={true}
        sortedCallback={onDataGridChange}
        filterable={false}
      />
      <div className="pl-2">
        {values.gevraagdeDocumenten.length === 0 && (
          <p>Er zijn nog geen documenten aangeleverd</p>
        )}
      </div>
    </div>
  );
};

export default connect(ReedsAangeleverdeDocumenten);
