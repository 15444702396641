import React, { ReactElement } from "react";
import { Card, useRequestInit, ErrorPage } from "adviesbox-shared";
import Logboek from "./logboek/logboek";
import Info from "./info/info";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { DossierProps, DossierSchemaType, dossierSchema } from "./infra/dossier-schema";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { FormikContextType } from "formik";
import { WithSaveData } from "../shared/utils/save-data";
import { SaveButton } from "../shared/components/save-button/save-button";
import { DossierRouteParams } from "../shared/types";
import { dossierOpslaan } from "./dossier-api";

const Dossier = (formikContext: FormikContextType<DossierSchemaType> & WithSaveData<DossierProps>): ReactElement => {
  const { user, params, settings } = useRequestInit<DossierRouteParams>();
  /* istanbul ignore else */ if (!user) return <ErrorPage error={Error("Geen gebruiker of geen rechten toegekend voor deze gebruiker")} data={null} />;
  /* istanbul ignore next */ const saveData = async () => await dossierOpslaan(settings, user, params.vestiging, params.dossierId, formikContext);
  return (
    <>
      <SaveButton context={{ ...formikContext, saveData }} />
      <div className="d-row">
        <div className="col-12 col-lg-6">
          <Card title="HDN Dossier">
            <Info />
          </Card>
          <Card title="HDN berichtenverkeer - Logboek">
            <Logboek />
          </Card>
          <DevDebug />
        </div>
      </div>
    </>
  );
};

Dossier.displayName = "Dossier";
export default withAdviesboxFormik<DossierProps & WithSaveData<DossierSchemaType>, DossierSchemaType>({
  mapPropsToValues: (e: DossierProps): DossierSchemaType => e,
  validationSchema: dossierSchema
})(Dossier);
