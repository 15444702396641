/* istanbul ignore file */

import React, { createContext, ReactElement, ReactNode, useCallback, useState } from "react";

type AppDataProviderProps = {
  children: ReactNode;
};

export type AppDataContextType = {
  reloadCount: number;
  reloadNavigation: () => void;
  activePage: string;
  setActivePage: React.Dispatch<React.SetStateAction<string>>;
  error: Error | null;
  setError: React.Dispatch<React.SetStateAction<Error | null>>;
};

export const AppDataContext = createContext({
  reloadCount: 0,
  reloadNavigation: () => 0,
  activePage: "",
  setActivePage: () => { },
  error: null,
  setError: () => null,
} as AppDataContextType);

export const AppDataProvider = ({ children }: AppDataProviderProps): ReactElement => {
  const [reloadNavigationCounter, setReloadNavigationCounter] = useState(1);
  const [error, setError] = useState<Error | null>(null);
  const reloadNavigation = useCallback(() => setReloadNavigationCounter(oldState => oldState + 1), [
    setReloadNavigationCounter
  ]);
  const [activePage, setActivePage] = useState("");

  return (
    <AppDataContext.Provider
      value={{
        error,
        setError,
        reloadCount: reloadNavigationCounter,
        reloadNavigation: reloadNavigation,
        activePage,
        setActivePage,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};
