/* istanbul ignore file */
import React, { ReactElement, useState } from "react";
import { Route } from "react-router-dom";
import DossierAjax from "../dossier/dossier-ajax";
import DocumentenAjax from "../documenten/documenten-ajax";
import NavigationList from "../navigation/NavigationList";
import classNames from "classnames";
import TopNavigation from "../shared/components/topnav-dossier/TopNavigation";
import { withErrorBoundary, Footer } from "adviesbox-shared";
import ZoekschermAjax from "../zoekscherm/zoekscherm-ajax";
import "./AuthenticatedHdnDossierAppWithNav.scss";

const AuthenticatedHdnDossierAppWithNav = (): ReactElement => {
  const [collapsed, setcollapsed] = useState(false);

  return (
    <div className="drawer-wrapper" data-testid="authenticated-app-with-nav">
      <div className={classNames("drawer-panel", { collapsed })}>
        <NavigationList collapsed={collapsed} collapse={(): void => setcollapsed(!collapsed)} />
      </div>
      <div className="drawer-content">
        <TopNavigation />
        <div className="authenticated-schermen">
          <Route path="/vestiging/:vestiging/zoeken" component={withErrorBoundary(ZoekschermAjax)} />
          <Route path="/vestiging/:vestiging/dossier/:dossierId/info" exact component={DossierAjax} />
          <Route path="/vestiging/:vestiging/dossier/:dossierId/documenten" exact component={DocumentenAjax} />
        </div>
        <Footer lastMutationDate={null} />
      </div>
    </div>
  );
};

AuthenticatedHdnDossierAppWithNav.displayName = "AuthenticatedHdnDossierAppWithNav";

export default AuthenticatedHdnDossierAppWithNav;
